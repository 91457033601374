import {Vis} from '../models/Vis'
import Stretch from '../models/Stretch'
import CollaboratorStretch from '../models/CollaboratorStretch'
import {BasePacket} from '../models/Packet'
import Price from '../models/Price'
import {IDisabledDay} from '../models/IDisabledDay'
import {ExtraLine, Line} from '../models/Line'
import Extra from '../models/Extra'
import Moment from 'moment'
import {IHora} from '../models/IHora'
import { getChannels } from 'Request/Request'

export type AddCallBackType = (row: string, number: number, id: string, toolTip: string) => void;
export type RemoveCallBackType = (row: string, number: number, toolTip: null) => void;

export const mountPaynoPainBody = (signature: string, amount: number, customer_ext_id: string, additional_info: string, service_uuid: string, url_post: string,
                                   url_ok: string, url_ko: string, description: string) => {

    return JSON.stringify({
        signature: signature,
        amount: amount,
        operative: "AUTHORIZATION",
        customer_ext_id: customer_ext_id,
        additional: additional_info,
        service: service_uuid,
        secure: true,
        url_post: url_post,
        url_ok: url_ok,
        url_ko: url_ko,
        description: description,
        save_card: false
    });
}


const mountLines = (prices: Price[], visitas: Vis, taxValue: number, taxName: string, tax_id: number): Line[] => {
    const lines: Line[] = [];

    for(let i = 0; i < prices.length; i++){
        const precio = prices[i];
        const quantity = visitas.vis[i];
        const taxBase = (quantity * precio.price).toFixed(2);
        const taxAmount = (+taxBase * taxValue).toFixed(2);
        lines.push({
            price: precio.price,
            discount: 0,
            quantity: quantity,
            taxBase: +taxBase,
            taxAmount: +taxAmount,
            taxValue: taxValue,
            tax_id: tax_id,
            taxName: taxName,
            total: Number(((precio.price + (precio.price * taxValue)) * quantity).toFixed(2)),
            passengerCategory_id: precio.passengerCategory.id,
            passengerCategoryName: precio.passengerCategory.name,
        });
    }

    return lines;
}

const mountExtraLines = (extras: Extra[]) => {
    const extraLines: ExtraLine[] = [];
    extras.forEach((extra) => {
        extraLines.push({
            price: +extra.price.toFixed(2),
            extra_id: extra.id,
            extraName: extra.name,
            description: extra.description,
            quantity: extra.quantity,
            taxBase: extra.price,
            taxAmount: extra.price * extra.tax.value,
            tax_id: extra.tax_id,
            taxName: extra.tax.name,
            taxValue: extra.tax.value,
            total: +(extra.price + extra.price * extra.tax.value).toFixed(2),
        });
    });
    return extraLines;
}

export const mountPayloadStretch = async (
    stretch: Stretch, 
    selectedDate: string, 
    selectedHour: IHora, 
    visitas: Vis, 
    lengthsName: 'stretch' | 'packet' | 'collaboratorStretch' | 'collaboratorPacket',
    publicToken: string
) =>  {
    const operationLine = stretch.operationLine
    const netAmount = +visitas.base.toFixed(2)
    const taxValue = +stretch.tax.value.toFixed(2)
    const qrTicket = operationLine.qrTicket + generateTokenticket(lengths[lengthsName] - operationLine.qrTicket.length)
    const taxAmount = netAmount * taxValue;
    const taxName = stretch.tax.name
    const total = +visitas.total.toFixed(2);
    const datebook = new Date(selectedDate)
    const datebookNTZ = new Date(Date.UTC(datebook.getFullYear(),datebook.getMonth(), datebook.getDate(), stretch.tramoHora ? selectedHour.hours : datebook.getHours(), stretch.tramoHora ? selectedHour.minutes : datebook.getMinutes(), 0, 0))

    if (stretch.tramoHora && selectedHour) {
        datebook.setHours(selectedHour.hours, selectedHour.minutes)
    }

    let channel;
    let channels = await getChannels(publicToken);
    if(channels.msg){
        channel = {id: 0} // Valor arbitrario si la pasarela no es TMT
    } else {
        channel = channels.filter(c=>stretch.primaryMoney.alphabeticCode === c.currencies);
        channel = channel[0]
    }

    const payload = {
        numero: qrTicket,
        datebook: datebook,
        datebookNTZ: datebookNTZ,
        hours: stretch.tramoHora ? selectedHour.hours : datebook.getHours(),
        minutes: stretch.tramoHora ? selectedHour.minutes : datebook.getMinutes(),
        datecheck: Moment(new Date()).format(),
        user_id: 0,
        username: "marketingpyme",
        netAmount: netAmount,
        discount: 0,
        taxAmount: taxAmount,
        taxName: taxName,
        total: total,
        terminal: 'web',
        operationLine_id: operationLine.id,
        operationLineName: operationLine.name,
        businessUnitName: operationLine.businessUnit.name,
        tin: operationLine.businessUnit.tin,
        stretch_id: stretch.id,
        stretchName: stretch.name,
        vuelta: stretch.vuelta,
        printPrice: stretch.printPrice,
        hoponHopoff: stretch.hoponHopoff,
        tramoHora: stretch.tramoHora,
        timeExpiration: stretch.timeExpiration,
        timeAdvance: stretch.timeAdvance,
        tramoHoraVuelta: stretch.tramoHoraVuelta,
        origin: stretch.origin,
        destination: stretch.destination,
        timeDelay: stretch.timeDelay,
        timeUse: stretch.timeUse,
        aditionalInfo: stretch.aditionalInfo,
        lines: mountLines(stretch.prices, visitas, taxValue, taxName, stretch.tax_id),
        extraLines: mountExtraLines(visitas.extras),
        money: stretch.primaryMoney.alphabeticCode,
        channel_id: channel.id,
        urlImage: stretch.dataweb ? stretch.dataweb.image1 : 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg', 
        payments: [
            {
                method: 'card',
                afiliate_id: null,
                afiliateName: null,
                afiliateTypeCommission: null,
                afiliateCommission: null,
                amount: total, // sólo un pago
                receipt: null
            }
        ],
    }
    return payload;
}
export const addVisitanteGlobal = (
    visitas: Vis,
    trayecto: Stretch | CollaboratorStretch | BasePacket,
    index: number,
    prices: Price[]
) => {
    if(trayecto && index && visitas && prices) {
        const names = visitas.names;
        const extras = visitas.extras.slice();
        const price = prices[index];
        const precioBase = price.price;
        const precio = precioBase + precioBase * trayecto.tax.value;
        let auxVis = visitas.vis.slice();
        let auxPrice = visitas.price.slice();
        let base = visitas.base;
        let tot = visitas.total;
        let disabledButtonsAux = visitas.disabledButtons;
        const indices = visitas.indices

        const reducer = (accumulator, curr) => accumulator + curr;
        const pasajerosTotal = auxVis.reduce(reducer) + 1
        
        if (!(prices[index].passengerCategory.typePrice.localeCompare('byGroup') === 0 && visitas.vis[index] >= 1)) {
            if(trayecto.capacity !== undefined && trayecto.capacity !== null){
                if (pasajerosTotal <= trayecto.capacity) {
                    auxPrice[index] += round(precio);
                    base += precioBase;
                    tot += precio;
                }
            }else{
                auxPrice[index] += round(precio);
                base += precioBase;
                tot += precio;
            }            
        } else (
            console.log('else')
        )

        if (!disabledButtonsAux) {
            disabledButtonsAux = true;
        }
        let limiteCapacidad = false;
        if(trayecto.capacity !== undefined && trayecto.capacity !== null){
            if (pasajerosTotal <= trayecto.capacity) {
                auxVis[index]++; 
            }
            if(pasajerosTotal >= trayecto.capacity){
                limiteCapacidad = true;
            }
        }else{
         auxVis[index]++;
        }
        return {auxVis:{ names: names, indices, vis: auxVis, price: auxPrice, base: base, total: round(tot), extras: extras, disabledButtons: disabledButtonsAux},limite:limiteCapacidad};
    } else {
        return null;
    }
}




export const quitarVisitanteGlobal = (visitas: Vis, trayecto: Stretch | BasePacket, index: number, prices: Price[]) => {
    if(trayecto && index && visitas && prices) {
        const price = prices[index];
        const precioBase = price.price;
        const precio = precioBase + precioBase * trayecto.tax.value;
        const names = visitas.names.slice()
        const indices = visitas.indices
        const extras = visitas.extras.slice();
        let auxVis = visitas.vis.slice();
        let auxPrice = visitas.price.slice();
        let tot = 0.00;
        let base = 0.00;
        let disabledButtonsAux = visitas.disabledButtons;

        if (prices[index].passengerCategory.typePrice.localeCompare('byGroup') === 0) {
            if (visitas.vis[index] > 1) auxVis[index]--;
            else if (visitas.vis[index] === 1) {
                auxVis[index]--;
                auxPrice[index] = precio * auxVis[index];
            }

        } else {
            if (auxVis[index] > 0) {
                auxVis[index]--;
                auxPrice[index] = precio * auxVis[index];
            }
        }

        let auxIndex: number;
        for (auxIndex = 0; auxIndex < auxVis.length; auxIndex++) {
            const auxPrecioBase = prices[auxIndex].price;
            const auxPrecio = auxPrecioBase + auxPrecioBase * trayecto.tax.value;

            if (prices[auxIndex].passengerCategory.typePrice.localeCompare('byGroup') === 0) {
                if (auxVis[auxIndex] >= 1) {
                    base += auxPrecioBase;
                    tot += auxPrecio;
                }
            } else {
                base += auxVis[auxIndex] * auxPrecioBase;
                tot += auxVis[auxIndex] * auxPrecio;
            }
        }

        if (tot === 0 && disabledButtonsAux) {
            disabledButtonsAux = false;
        }
        return { indices, names: names, vis: auxVis, price: auxPrice, base: base, total: tot, extras: extras, disabledButtons: disabledButtonsAux };
    } else {
        return null;
    }
}

export const getIndexForMapeo = ( itemSelected: Stretch | CollaboratorStretch) => {
    const isCollaboratorStretch = !!(itemSelected as CollaboratorStretch).operator_id
    // @ts-ignore
    return !isCollaboratorStretch? itemSelected.id : String(itemSelected.id) + '_' + String((itemSelected as CollaboratorStretch).operator_id)
}

export const checkDisabledDay = (disabledDays: IDisabledDay[], day: Date) => {
    if (!disabledDays) {
        return false
    }

    return disabledDays
        .findIndex(disableDay =>
            disableDay.year === day.getFullYear() &&
            disableDay.month === day.getMonth() &&
            disableDay.day === day.getDate()
        ) !== -1
}

// devuelve true si hay al menos un pasajero añadido en visitas.vis
export const visOK = (visitas) => {
    const vis = visitas.vis
    let visOK = false
    for (let i=0; i<vis.length; i++) {
        if (vis[i] > 0) {
            visOK = true
            break
        }
    }
    return visOK
}

export const lengths = {
    stretch: 16,
    packet: 17,
    collaboratorStretch: 18,
    collaboratorPacket: 19,
    cart: 20
}

export const round = (n, decimals = 2) => {
    return Number(Math.round(Number(n + 'e' + decimals)) + 'e-' + decimals)
}

export const primeraLetraMayuscula = (cadena: string) => {
    const primerCaracter = cadena.charAt(0).toUpperCase();
    const restoDeLaCadena = cadena.substring(1, cadena.length);
    return primerCaracter.concat(restoDeLaCadena);
};

const randomString = (length: number, chars: string) => {
    let result = '';
    for(let i = length; i > 0; i--) result += chars[Math.floor(Math.random() * chars.length)]
    return result;
}

export const generateTokenticket = (numChars: number) => {
    return randomString(numChars, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ');
}

export const visSanited = (visitas: Vis): Vis => {
    // @ts-ignore
    let visitasSanited: Vis = {}
    Object.assign(visitasSanited, visitas)

    visitas.vis.forEach((item, index) => {
        if (item === 0) {
            visitasSanited.vis.splice(index, 1)
            visitas.price.splice(index, 1)
            visitas.names.splice(index, 1)
        }
    })
    return visitasSanited
} 

export const horasIdaAvailable = (horasIda, dayDisabled) => {
    const horasIdaReduced = horasIda.reduce((previous, horaIda) => {
        const position = dayDisabled.hours.findIndex((iHour) => horaIda.hours === iHour.hours && horaIda.minutes === iHour.minutes)
        if (position === -1) {
            previous.push(horaIda)
        }
        return previous
    }, [])
    return horasIdaReduced
}

export const deepFreeze = (object)  => {
    Object.getOwnPropertyNames(object).forEach(name => {
      const property = object[name]
      // if property is an object, freeze it recursively
      if (property && typeof property === 'object')
        deepFreeze(property)
    })
    // at the end, just return the freezed object
    return Object.freeze(object)
  }