import  { useState } from "react";
import Moment from "moment";
import ICartItem from '../../models/ICartItem'
import Spinner from "reactstrap/es/Spinner";
import { MyTimer } from "components/MyTimer";
import Swal from "sweetalert2";

interface Props {
    money: any
    item: ICartItem
    handleDeleteCartItem: (numero: string) => void
    t: any
    deleting: boolean
    numero: any
}

export const isCartTicket = (item: ICartItem) => {
    return !isCartPacketTicket(item) && !isCartCollaboratorTicket(item) && !isCartCollaboratorPacketTicket(item)
}

export const isCartPacketTicket = (item: ICartItem) => {
    return !!item.packetTicket && !item.belongCollaboratorPacket
}

export const isCartCollaboratorTicket = (item: ICartItem) => {
    return !item.packet_id && item.collaborator_id && !item.belongCollaboratorPacket
}

export const isCartCollaboratorPacketTicket = (item: ICartItem) => {
    return !!item.packet_id && item.belongCollaboratorPacket
}


export const CartItem = ({ item, handleDeleteCartItem, money, t, deleting, numero}: Props) => {
    let name, lines, total, image, tramoHora
    let isPacket = undefined;
    const [expired, setExpired] = useState<boolean>(false)

    if (isCartTicket(item) || isCartCollaboratorTicket(item)) {
        isPacket = false;
        name = item.ticket.stretchName
        lines = item.ticket.lines
        total = item.ticket.visitas.total
        tramoHora = item.ticket.tramoHora
        if(item.ticket){
            if(item.ticket.urlImage){
                image = item.ticket.urlImage.replace('http://','https://');
            }else{
                image = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
            } 
        }
    } else if (isCartPacketTicket(item) || isCartCollaboratorPacketTicket(item)) {
        isPacket = true;
        name = item.packetTicket.packet.name
        lines = item.packetTicket.lines
        total = item.packetTicket.visitas.total
        tramoHora = item.packetTicket.tramoHora
        if(item.packetTicket){
            if(item.packetTicket.urlImage){
                image = item.packetTicket.urlImage.replace('http://','https://');
            }else{
                image = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
            } 
        }
    } else {
        console.timeLog('no deberia estar aqui')
    }

    const expiredHandler = () => {
        setExpired(true)

        Swal.fire({
            icon: 'error',
            title: `${t("timedOut")}`,
            html:
            `${t("cartItem")}: <b>${name}</b> ${t("cartItemII")}`
        })
    }
  
    const expire_at = new Date(item.expire_at)
    const treintaMins = new Date(item.created_at)
    treintaMins.setSeconds(treintaMins.getSeconds()+30*60)

    return (
        <>
            <div className="row destinations-details__content sidebar__item">
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-3">
                            <img src={image} alt={name} className="img-fluid" />
                        </div>

                        <div className="col-sm-7">
                            {
                                expired && 
                                <>
                                <h4 style={{opacity: '30%',marginBottom: '7px', color: 'red'}} className={isPacket ? "tramoSeleccionadoPacket" : "tramoSeleccionado"}>{name}</h4>
                                <h5 style={{color: 'red', opacity: '85%'}}>{t("necessaryDelete")}</h5>
                                </>
                            }
                            {   !expired &&
                                <h4 className={isPacket ? "tramoSeleccionadoPacket" : "tramoSeleccionado"}>{name}</h4>
                            }
                            {!(isCartPacketTicket(item) || isCartCollaboratorPacketTicket(item)) &&
                            <div>
                                <h6>

                                    {tramoHora ? Moment(item.datebook).format('DD-MM-YYYY HH:mm') : Moment(item.datebook).format('DD-MM-YYYY')}
                                </h6>
                            </div>
                            }
                            {
                                item.packetTicket && item.packetTicket.tickets && item.packetTicket.tickets.map(ticket =>
                                    <div>
                                        <h6>
                                            {ticket.stretchName} {ticket.tramoHora ? Moment(ticket.datebook).format('DD-MM-YYYY HH:mm') : Moment(ticket.datebook).format('DD-MM-YYYY')}
                                        </h6>
                                    </div>
                                )
                            }
                            {
                                item.collaboratorTickets && item.collaboratorTickets.map(cartTicket =>
                                    <div>
                                        <h6>
                                            {cartTicket.stretchName} {Moment(cartTicket.datebook).format('DD-MM-YYYY HH:mm')}
                                        </h6>
                                    </div>
                                )
                            }
                            {
                                !deleting && 
                                <div><button className="btn btn-underline" onClick={() => handleDeleteCartItem(item.numero)} >{t("delete")}</button></div>

                            }
                            {
                               item.numero !== numero && deleting && 
                                <div><button className="btn btn-underline" onClick={() => handleDeleteCartItem(item.numero)} >{t("delete")}</button></div>
                            }
                            {
                                item.numero === numero && deleting &&
                                <div>
                                    
                                        <Spinner/>
                                    
                                </div>
                            }
                        </div>
                        <div className="col-sm-2 col-6">
                        {/*<MyTimer expiryTimestamp={new Date(item.expire_at)} />*/}
                        <MyTimer expiryTimestamp={item.expire_at !== null ? expire_at : treintaMins} expiredHandler={expiredHandler} t={t}/>
                </div>
                    </div>

                </div>

                <div className="col-sm-2 col-6">
                    <div>
                        {
                            lines.map((line) => {
                                return (
                                    <p key={line.passengerCategory_id}>
                                        {line.quantity !== 0 && line.passengerCategoryName + ": " + line.quantity}
                                    </p>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="col-sm-2 col-6 text-right">
                    <p>
                        {total.toFixed(2)} {money} {/*money == 'EUR' ? '€' : '$'*/}
                    </p>
                </div>
            </div>
        </>
    )
}

export const TypePrice = {
    byPassenger: 'byPassenger', // por pasagero
    byGroup: 'byGroup', // precio fijo independientemente de los pasajeros que vayan
}