import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import SeatPicker from "react-seat-picker";
import Moment from 'moment';
// import '../styles/style.css';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router';
import {addCollaboratorStretchToCart, getCollaboratorStretchesData} from './Request'
import {
    AddCallBackType,
    RemoveCallBackType,
    addVisitanteGlobal,
    generateTokenticket,
    mountPayloadStretch, 
    primeraLetraMayuscula,
    quitarVisitanteGlobal,
    visOK,
    horasIdaAvailable
} from '../Util'
import {TypeTicket} from '../../models/TypetTicket'
import {HeaderView} from '../../components/HeaderView'
import {NoFound} from '../../components/NotFound'
import {Vis} from '../../models/Vis'
import CollaboratorStretch from '../../models/CollaboratorStretch'
import {ISeat} from '../../models/ISeat'
import Extra from '../../models/Extra'
import {IHora} from '../../models/IHora'
import { FooterView } from 'components/FooterView';
import { PricesView } from 'components/PricesView';
import { getChannels } from 'Request/Request';
import { useTranslation } from 'react-i18next';
import {Carousel} from "react-responsive-carousel"
import { InfoWeb } from 'components/InfoWeb'
import { modalError, modalInfo } from 'Modules/Payment/Request';
import Spinner from 'reactstrap/es/Spinner';
import CalendarAvailable from 'components/CalendarAvailable';
import * as Sentry from "@sentry/react";

interface ParamsProps {
    stretch_id: string
    operator_id: string
    public: string
}

type Props = RouteComponentProps<ParamsProps>;

export const CollaboratorStretchPage: React.FC<Props> = (props: Props) => {

    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }

    const publicToken = props.match.params.public
    const stretchID = Number(props.match.params.stretch_id)
    const operator_id = Number(props.match.params.operator_id)

    const history = useHistory();
    
    const init: Vis = { names: [], indices: [], vis: [], price: [], base: 0.00, total: 0.00, extras: [], disabledButtons: false };
    const CHECKED_CLASSNAME = "btn btn-primary";
    const UNCHECKED_CLASSNAME = "btn btn-outline-primary";
    // const COLLABORATOR_LENGTH = 20;

    const [trayecto, setTrayecto] = useState<CollaboratorStretch>();

    const [openDatePopup, setOpenDatePopup] = useState<boolean>(false);
    const [openReservarPopup, setOpenReservarPopup] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [visitas, setVisitas] = useState<Vis>(init);
    const [, setDD] = useState<Object[]>([]);
    const [dateClicked, setDateClicked] = useState<string>("");
    const [selectedHour, setSelectedHour] = useState<IHora>();
    const [totalSeats, setTotalSeats] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [trayectoIdaVuelta, setTrayectoIdaVuelta] = useState<boolean>(false);
    const [soloIdaClassName, setSoloIdaClassName] = useState<string>(CHECKED_CLASSNAME);
    const [idaVueltaClassName, setIdaVueltaClassName] = useState<string>(UNCHECKED_CLASSNAME);
    const [channels, setChannels] = useState<any>();
    const [disabledCanal, setDisabledCanal] = useState<boolean>(false)
    const [adding, setAdding] = useState<boolean>(undefined);
    const [limiteCapacidad,setLimiteCapacidad] = useState<boolean>(false)
    const [horasDisponibles, setHorasDisponibles] = useState([])
    const [monedasGreenpay, setMonedasGreenpay] = useState<any>();

    let description = useRef('');
    let amount = 0;
    let canalSi = false;

    useEffect(()=> {
        if(!channels){
            getChannels(publicToken).then((res)=>{
                if(res.msg === 'No TMT'){
                    res = 'notmt';
                }else if(res.msg === 'GREENPAY'){
                    setMonedasGreenpay(res.monedasDisponibles)
                    res = 'greenpay'
                }
                setChannels(res);
            })
        }
    },[channels])

    useEffect(() => {
        i18n.changeLanguage(idioma);  
        if (!trayecto) {
            getCollaboratorStretchesData(publicToken).then(result => {
                if(result.statusCode === 401){
                    Sentry.captureException(new Error("CollaboratorStretch - Cliente no encontrado"));
                    history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                    return null;
                }
                const trayecto = result.find(value => value.id === Number(stretchID) && value.operator_id === Number(operator_id))
                setTrayecto(trayecto)
            }).catch(e => {
                Sentry.captureException(new Error("CollaboratorStretch - getCollaboratorStretchesData"));
                history.push(`/${publicToken}/error/Ha ocurrido un error - getCollaboratorStretchesData`)
            })

        } else if (trayecto) {
            description.current = trayecto.name;
            let auxDD: Object[] = [];
            if (trayecto.disabledDays) {
                trayecto.disabledDays.forEach((disabledDay) => {
                    let str = '{ "title": "No Disponible", ';
                    const date = new Date(disabledDay.year, disabledDay.month, disabledDay.day);
                    str += '"date": "' + Moment(date).format("YYYY-MM-DD") + '", ';
                    str += '"constraint": "noDisponible", ';
                    str += '"backgroundColor": "#ff9f89", '
                    str += '"display": "background"';
                    str += '}';
                    let strjs = JSON.parse(str);
                    auxDD.push(strjs);
                });

                setDD(auxDD);
            }
            if (trayecto.prices) {

                setDisabled(trayecto.prices.length === 0);
                let auxVis: number[] = [];
                let auxPrice: number[] = [];
                let auxNames: string[] = [];
                const indices: number[] = []
                trayecto.prices.forEach((precio) => {
                    auxVis.push(0);
                    auxPrice.push(0.00);
                    auxNames.push(precio.passengerCategory.name);
                    indices.push(precio.passengerCategory.id)
                });
                setVisitas({
                    names: auxNames,
                    indices,
                    vis: auxVis,
                    price: auxPrice,
                    base: 0.00,
                    total: 0.00,
                    extras: [],
                    disabledButtons: false
                });

                setTotalSeats(0);
            } else {
                setDisabled(true);
            }
            const horas = trayecto.horasIda;
            if (horas && horas.length !== 0 && !selectedHour) {
                setSelectedHour(horas[0])
                setHorasDisponibles(horas)
            }
        }
        if(trayecto){
            if(channels && channels !== 'notmt' && channels !== 'greenpay'){
 
                channels.map(c=>{
                   
                    if(c.currencies === trayecto.primaryMoney.alphabeticCode){
                        canalSi = true;
                    }
                })
                setDisabledCanal(!canalSi)
            }else if(channels && channels === 'greenpay'){
                let moneda = trayecto.primaryMoney.alphabeticCode
                if(monedasGreenpay.includes(moneda)){
                    canalSi = true;
                }
                setDisabledCanal(!canalSi)
            }
        }
    }, [trayecto,horasDisponibles, channels]);

    const clickDateHandler = (arg: any) => {
        const date = arg.date;
        let pertenece: boolean = false;

        if(trayecto){
            if(trayecto.disabledDays){
                    // miro si el día clicado está en deshabilitados
                    const position = trayecto.disabledDays.findIndex(disabledDay =>
                        disabledDay.day === date.getDate() && disabledDay.month === date.getMonth() && disabledDay.year === date.getFullYear()
                    )
                    if (position === -1) { // no está en disabledDays, el día está habilitado
                        if (trayecto.tramoHora && trayecto.horasIda) {
                            setHorasDisponibles(trayecto.horasIda)
                        }
                    } else { // el día está en disabledDays. puede ocurrir varias condiciones
                        // busco el día deshabilitado
                        const dayDisabled = trayecto.disabledDays[position]
    
                        // tramoHora: si hours.length === 0 all dia deshabilitado, si hours.length >0 tiene horas deshabilitadas
                        if (!trayecto.tramoHora) {// no es tramoHora, el día está deshabilitado
                            pertenece = true
                        } else {
                            if (dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
                                setHorasDisponibles([])
                                pertenece = true
                            } else {
                                const horasIda = trayecto.horasIda || []
                                const horasIdaReduced = horasIdaAvailable(horasIda, dayDisabled)
                                setHorasDisponibles(horasIdaReduced)
                                if (horasIdaReduced.length === 0) {
                                    pertenece = true
                                }
                            }
                        }
                    }                   
            }else {
                if (trayecto.tramoHora && trayecto.horasIda) {
                    setHorasDisponibles(trayecto.horasIda)
                }
            }           
        }
        let todaysDate = new Date()
        todaysDate.setHours(0, 0, 0, 0)

        let dateParsed = new Date(date)
        const beforeToday = dateParsed < todaysDate
        if (!pertenece && !beforeToday) {
            //"2020-08-04T14:58:01.415Z"
            setDateClicked(Moment(date).format())
        } else {
            setOpenDatePopup(true)
        }
        /*if (trayecto && trayecto.disabledDays) {
            trayecto.disabledDays.forEach((disabledDay) => {
                const dateDisabledDay = Moment(new Date(disabledDay.year, disabledDay.month, disabledDay.day)).format("YYYY-MM-DD");
                if (date === dateDisabledDay) pertenece = true;
            });
        }
        let todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);

        let dateParsed = new Date(date);
        const beforeToday = dateParsed < todaysDate;
        if (!pertenece && !beforeToday) {
            //"2020-08-04T14:58:01.415Z"
            setDateClicked(Moment(date).format());
        } else {
            setOpenDatePopup(true);
        }*/
    }

    const closeClickDateHandler = () => {
        setOpenDatePopup(false);
    };

    const closeClickReservarHandler = () => {
        setOpenReservarPopup(false);
    };

    const addVisitantePrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const {auxVis, limite} = addVisitanteGlobal(visitas, trayecto, index, trayecto.prices);
            if(auxVis !== null) setVisitas(auxVis);
            setLimiteCapacidad(limite)
            let auxTot = totalSeats;
            auxTot++;
            setTotalSeats(auxTot);
        }
    };
    const addVisitanteVueltaPrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const {auxVis} = addVisitanteGlobal(visitas, trayecto, index, trayecto.vueltaPrices);
            if (auxVis !== null) setVisitas(auxVis);

            let auxTot = totalSeats;
            auxTot++;
            setTotalSeats(auxTot);
        }
    };

    const quitarVisitantePrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, trayecto.prices);
            if (auxVis !== null) setVisitas(auxVis);
            setLimiteCapacidad(false)
            let auxTot = totalSeats - 1;
            setTotalSeats(auxTot);
        }
    };

    const quitarVisitanteVueltaPrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, trayecto.vueltaPrices);
            if (auxVis !== null) setVisitas(auxVis);

            let auxTot = totalSeats - 1;
            setTotalSeats(auxTot);
        }
    };

    const extrasChangeHandler = useCallback((event) => {
        const index = event.currentTarget.value;
        if (trayecto && trayecto.extras) {
            const extra = trayecto.extras[index];
            const precioBaseExtra = extra.price;
            const precioTotalExtra = extra.price + extra.price * extra.tax.value;
            if (event.currentTarget.checked) {
                // Añadir el extra al presupuesto
                let auxExtras = visitas.extras.slice();
                auxExtras.push(extra);
                setVisitas({
                    names: visitas.names,
                    indices: visitas.indices,
                    vis: visitas.vis,
                    price: visitas.price,
                    base: visitas.base + precioBaseExtra, total: visitas.total + precioTotalExtra,
                    extras: auxExtras,
                    disabledButtons: visitas.disabledButtons
                });
            } else {
                // Eliminar el extra del presupuesto
                let auxExtras = visitas.extras.slice();
                let indexExtra = -1;
                auxExtras.forEach((res) => { if (res.id === extra.id) indexExtra = auxExtras.indexOf(res) });
                auxExtras.splice(indexExtra, 1);
                setVisitas({
                    names: visitas.names,
                    indices: visitas.indices,
                    vis: visitas.vis,
                    price: visitas.price,
                    base: visitas.base - precioBaseExtra,
                    total: visitas.total - precioTotalExtra,
                    extras: auxExtras,
                    disabledButtons: visitas.disabledButtons
                });
            }
        }
    }, [trayecto, visitas]);

    const horaChangeHandler = (event: any) => {
        const index = event.currentTarget.value;
        if (index !== undefined && trayecto) {
            setSelectedHour(trayecto.horasIda[index]);
        }
    };

    const addSeatCallback = async ({ row, number, id }: ISeat, addCb: AddCallBackType) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1500));
        const newTooltip = 'Seleccionado';
        addCb(row, number, id, newTooltip);
        setLoading(false);
    };

    const removeSeatCallback = async ({ row, number, id }: ISeat, removeCb: RemoveCallBackType) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1500));
        const newTooltip = null;
        removeCb(row, number, newTooltip);
        setLoading(false);
    };

    const trayectoIdaVueltaClickHandler = (event: any) => {
        setTrayectoIdaVuelta(true);
        setSoloIdaClassName(UNCHECKED_CLASSNAME);
        setIdaVueltaClassName(CHECKED_CLASSNAME);
    }

    const trayectoSoloIdaClickHandler = (event: any) => {
        setTrayectoIdaVuelta(false);
        setSoloIdaClassName(CHECKED_CLASSNAME);
        setIdaVueltaClassName(UNCHECKED_CLASSNAME);
    }

    const addCart = () => {
        if (!dateClicked) {
            setOpenDatePopup(true);
        } else {
            setAdding(true)
            amount = (+ visitas.total.toFixed(2));
            // TODO
            if (trayecto) {
                let tokenTicket = localStorage.getItem('tokenTicket');
                if (!tokenTicket) {
                    tokenTicket = generateTokenticket(20);
                    localStorage.setItem('tokenTicket', tokenTicket);
                }
                
                 mountPayloadStretch(
                    trayecto,
                    dateClicked, 
                    selectedHour,
                    visitas,
                    'collaboratorStretch',
                    publicToken
                ).then((ticket: any) => {
                    ticket.type = TypeTicket.CollaboratorStretch
                    ticket.operator_id = trayecto.operator_id
                    ticket.additional = additional
                    ticket.visitas = visitas 
                    ticket.prices = trayecto.prices
                    ticket.tax = trayecto.tax
    
                    const uuid = localStorage.getItem('tokenTicket');
    
                    addCollaboratorStretchToCart(ticket, publicToken).then((res) => {
                        setAdding(false)
                        let message: string = "";
                        let info = false;
                        switch (res.code) {
                            case 200: 
                                if (!uuid) {
                                    localStorage.setItem('tokenTicket', res.payload.token)
                                }
                                history.push({
                                    pathname: `/${publicToken}/cart/`
                                })
                                break;
                            case 400:
                                message = t("tokenNoExists"); // El token no existe.
                                break
                            case 404:
                                message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                                break
                            case 410:
                                message = t("errorCreateTicket") //"Error al generar el ticket."
                                break
                            case 412:
                                message = t("obtainError") //"Hemos obtenido un error."
                                break
                            case 413:
                                info = true;
                                message = `${ticket.tramoHora ? t("noDisponibility") : 'En este momento no tenemos disponibilidad para el día seleccionado.'}  ${t("thenDisponibility")}`  //t("notEnoughCapacity") //"No hay aforo suficiente."
                                break
                            case 414:
                                info = true;
                                message =  t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                                break
                            case 415:
                                info = true;
                                message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                                break
                            case 416:
                                info = true;
                                message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                                break
                            case 500:
                                message = t("serverError") //"Error interno del servidor."
                                break
                            default:
                                message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                                break                
                        }
                        if(message !== ""){
                            if(info) {
                                modalInfo(message, t)
                            } else {
                                modalError(message)
                            }     
                        }
                        // Comprobamos si es un número
                        /*if ((/^[0-9]*$/).test(res)) {
                            const statusCode: number = +res;
                            let message: string = "";
                            switch (statusCode) {
                                case 400:
                                    message = "El token no existe.";
                                    break;
                                case 404:
                                    message = 'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.';
                                    break;
                                case 410:
                                    message = "Error al generar el ticket.";
                                    break;
                                case 412:
                                    message = "Hemos obtenido un error.";
                                    break;
                                case 413:
                                    message = "No hay aforo suficiente.";
                                    break;
                                case 414:
                                    message = "El servicio que quiere reservar no está disponible.";
                                    break;
                                case 415:
                                    message = "El servicio está deshabilitado para esa fecha.";
                                    break;
                                case 416:
                                    message = "El servicio está deshabilitado para esa hora.";
                                    break;
                                case 500:
                                    message = "Error interno del servidor.";
                                    break;
                                default:
                                    message = "Lo siento, hemos obtenido un error inesperado.";
                                    break;
                            }
                            Sentry.captureException(new Error(`CollaboratorStretch - ${message}`));
                            history.push(`${publicToken}/error/${message}/`);
                        } else { // En caso de no ser un número, es la url
                            if (!uuid) {
                                localStorage.setItem('tokenTicket', res.uuid);
                            }
                            history.push({
                                pathname: `/${publicToken}/cart/`,
                                search: publicToken
                            })
                        }*/
                    }).catch(e => {
                        setAdding(false)
                        Sentry.captureException(new Error(`CollaboratorStretch - Añadir`));
                        modalError(t("errorAddCart"))
                    });
                });

                const additional = {
                    stretchName: trayecto.name,
                    stretchDescription: trayecto.description,
                    tokenticket: tokenTicket,
                    description: description
                }
            }
        }
    };

    let disableAddCart = true
    if (trayecto) {
        disableAddCart = !visOK(visitas) || dateClicked === ''
    }

    const images = []
    if (trayecto && trayecto.dataweb) {
        for (let i=1; i<=5; i++) {
            if (trayecto.dataweb[`image${i}`]) {
                if(trayecto.dataweb[`image${i}`] !== " "){
                    const temp = {
                        original: trayecto.dataweb[`image${i}`].replace('http://','https://'),
                        thumbnail: trayecto.dataweb[`image${i}`].replace('http://','https://')
                    }
                    images.push(temp)
                }               
            }
        }
    }
    if(images.length === 0){
        images.push(
            {
                original: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg",
                thumbnail: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg"
            })
    }

    return (
        <div className="page-wrapper animated fadeIn">
            {
                trayecto && publicToken &&
                <>
                    <HeaderView onCartClick={() => history.push({
                        pathname: `/${publicToken}/cart`,
                    }) } publicToken={publicToken}/>                

                </>
            }
            <section className="page-header tour-two tour-list destinations-details">
                <div className="container">
                    <div className="row">

                        {
                            !trayecto &&
                            <NoFound/>
                        }

                        {
                            trayecto &&
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xs-12">
                                        {
                                            disabledCanal && channels !== 'greenpay' &&
                                            <div style={{backgroundColor:'red', color: 'white'}}>
                                                {t("noMoneyTMT")}
                                            </div>
                                        }   
                                        {
                                            disabledCanal && channels === 'greenpay' &&
                                            <div style={{backgroundColor:'red', color: 'white'}}>
                                                {t("noMoneyGreenpay")}
                                            </div>
                                        }                                 
                                <div className="destinations-details__content">
                                    <h3 className="destinations-details__title">{trayecto.name}</h3>

                                    <Carousel
                                        autoPlay
                                        infiniteLoop
                                        showStatus={false}
                                    >
                                        {
                                            images.map(image => {
                                                return(
                                                    <div>
                                                        <img src={image.original.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")} alt=''/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    {                                                 
                                        <InfoWeb trayecto = {trayecto} orden={1}/>
                                    }                                  
                                </div>
                            </div>
                        }
                        {
                            trayecto &&
                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                                <div className="tour-sidebar">
                                    <div className="tour-sidebar__featured">
                                        <div className="book-online-cta">
                                            <h2>{t("bookingOnline")}</h2>
                                            <ul>
                                                <li>
                                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-clock"
                                                         className="icon-clock" width="13" height="13" viewBox="0 0 13 13"
                                                         ng-svg="icon-clock">
                                                        <path
                                                            d="M6.47,0a6.47,6.47,0,1,0,6.47,6.47h0A6.47,6.47,0,0,0,6.47,0m0,11.5a5,5,0,1,1,5-5,5,5,0,0,1-5,5m2.16-5H7.19V3.59a.72.72,0,1,0-1.44,0v3.6a.72.72,0,0,0,.72.72H8.63a.72.72,0,0,0,0-1.44"/>
                                                    </svg>
                                                    {t("availability")}
                                                </li>
                                                <li>
                                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-bolt"
                                                         className="icon-bolt" width="9" height="14" viewBox="0 0 9 14"
                                                         ng-svg="icon-bolt">
                                                        <path
                                                            d="M8.88,5.91a.88.88,0,0,0-.79-.39H6V.82A.77.77,0,0,0,5.53.05a.8.8,0,0,0-.85.21l-4.57,7a.88.88,0,0,0,0,.87.88.88,0,0,0,.79.39H3l.12,4.7a.78.78,0,0,0,.45.77.86.86,0,0,0,.27.05.83.83,0,0,0,.58-.26l4.42-6.9,0-.06A.88.88,0,0,0,8.88,5.91Z"/>
                                                    </svg>
                                                    {t("confirmation")}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="fechas">

                                                <CalendarAvailable
                                                disabledDays={trayecto.disabledDays}
                                                horasIda={trayecto.horasIda}
                                                clickDateHandler={clickDateHandler}
                                            />
                                            {
                                                dateClicked !== "" &&
                                                <p className="lead">
                                                    <b>{t("dateSelected")}:</b> {Moment(dateClicked).format("DD/MM/YYYY")}
                                                </p>
                                            }
                                        </div>
                                        <Dialog onClose={closeClickDateHandler} aria-labelledby="customized-dialog-title" open={openDatePopup} >
                                            <DialogTitle id="customized-dialog-title">{t("dateNotAvailable")}</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>{t("dateNotAvailablemsg")}</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary" onClick={closeClickDateHandler} >{t("close")}</button>
                                            </DialogActions>
                                        </Dialog>
                                        {
                                            trayecto && trayecto.tramoHora && horasDisponibles && horasDisponibles.length > 0 &&

                                            <div className="horas">
                                                <h3>{t("departureTime")}</h3>
                                                <select className="custom-select" id="hourSelect" onChange={horaChangeHandler} >
                                                    {
                                                        horasDisponibles.map((hora) => {
                                                            const horaString = ((hora.hours.toString().length === 1) ? "0" : "") + hora.hours.toString() + hora.minutes.toString();
                                                            const horaSalida = Moment(horaString, "HHmm").format('HH:mm');
                                                            return (
                                                                <option value={trayecto.horasIda.indexOf(hora)} key={hora.id}>
                                                                    {horaSalida}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }

                                        {
                                            trayecto.vuelta && trayecto.vueltaPrices &&
                                            trayecto.vueltaPrices.length !== 0 &&
                                            <div className="opciones">
                                                <br/>
                                                <div className="btn-group">
                                                    <button type="button" className={soloIdaClassName} onClick={trayectoSoloIdaClickHandler} disabled={visitas.disabledButtons}>{t("singleJourney")}</button>
                                                    <button type="button" className={idaVueltaClassName} onClick={trayectoIdaVueltaClickHandler} disabled={visitas.disabledButtons}>{t("roundTrip")}</button>
                                                </div>
                                            </div>
                                        }
                                        <PricesView
                                            prices={trayecto.prices}
                                            money={trayecto.primaryMoney ? trayecto.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                            tax={trayecto.tax}
                                            visitas={visitas}
                                            addVisitantePrices={addVisitantePrices}
                                            quitarVisitantePrices={quitarVisitantePrices}
                                            limiteCapacidad={limiteCapacidad}
                                        />

                                        {
                                            trayecto.vuelta && trayectoIdaVuelta && trayecto.vueltaPrices.length !== 0 && trayecto.vueltaPrices.map((precio) => {
                                                const precioViaje = precio.price + precio.price * trayecto.tax.value;
                                                const index = trayecto.vueltaPrices.indexOf(precio);
                                                return (
                                                    <div className="opciones">
                                                        {
                                                            precio.passengerCategory.name && precio.price > 0 &&
                                                            <div className="row row-cols-auto text-left align-items-center">
                                                                <div className="col-5">
                                                                    <div className="row row-cols-auto">

                                                                        <div className="col">
                                                                            <strong> {primeraLetraMayuscula(precio.passengerCategory.name)}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row row-cols-auto">

                                                                        <div className="col">
                                                                            {precioViaje.toFixed(2)} {trayecto.primaryMoney ? trayecto.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 nopadbado">
                                                                    {visitas.price[index] && visitas.price[index].toFixed(2)} {trayecto.primaryMoney ? trayecto.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                                                </div>
                                                                <div className="col-2 nopadbad">
                                                                    <button className="w-100 btn btn-lg btn-outline-primary" value={index} onClick={quitarVisitanteVueltaPrices} name="delete" ><span>-</span></button>
                                                                </div>
                                                                <div className="col-1 nopadbado">
                                                                    {visitas.vis[index]}
                                                                </div>
                                                                <div className="col-2 nopadbad">
                                                                    <button className="w-100 btn btn-lg btn-outline-primary" value={index} name="add" onClick={addVisitanteVueltaPrices} ><span>+</span></button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                        {
                                            disabled &&
                                            <div>
                                                {t("experienceNotAvailablemsg")}
                                            </div>
                                        }
                                         

                                        <Dialog onClose={closeClickReservarHandler} aria-labelledby="customized-dialog-title" open={openReservarPopup} >
                                            <DialogTitle id="customized-dialog-title">{t("selectItem")}</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>{t("selectItemmsg")}</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary" onClick={closeClickReservarHandler} >{t("close")}</button>
                                            </DialogActions>
                                        </Dialog>
                                        {
                                            trayecto.extras && trayecto.extras.length !== 0 &&
                                            <h3 className="preciosHeading">
                                                Extras
                                            </h3>
                                        }

                                        {
                                            trayecto.extras && trayecto.extras.length !== 0 &&
                                            trayecto.extras.map((extra: Extra) => {
                                                return (
                                                    <div className="input-group mb-3" key={extra.id} >
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                <input type="checkbox" name={extra.name}
                                                                       value={trayecto.extras.indexOf(extra)} key={extra.id} id={extra.id.toString()} onChange={extrasChangeHandler} />
                                                            </div>
                                                        </div>
                                                        <input type="text" className="form-control" readOnly value={extra.description + " - " + (extra.price + extra.price * extra.tax.value).toFixed(2) + ' ' + 'trayecto.primaryMoney.alphabeticCode'} />
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            trayecto.seats &&
                                            <>
                                                <SeatPicker
                                                    addSeatCallback={addSeatCallback}
                                                    removeSeatCallback={removeSeatCallback}
                                                    rows={trayecto.seats}
                                                    maxReservableSeats={totalSeats}
                                                    alpha
                                                    visible
                                                    selectedByDefault
                                                    loading={loading}
                                                    tooltipProps={{ multiline: true }}
                                                />
                                            </>
                                        }


                                        <div className="book-form-totals">

                                            <p className='totalAlign'>
                                                <b>Total: </b> {visitas.total.toFixed(2)} {trayecto.primaryMoney ? trayecto.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                            </p>
                                        </div>



                                        {
                                            !adding && 
                                            <button type="button" disabled={disableAddCart || disabled || disabledCanal} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" onClick={addCart} >{t("addCart")}</button>
   
                                        }
                                        {
                                            adding && 
                                            <button
                                            type="button" disabled={disableAddCart || disabled || disabledCanal} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" >
                                               <Spinner/>
                                            </button>
                                        }
                                        {                                                 
                                            <InfoWeb trayecto = {trayecto} orden={2}/>
                                        }  
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </div>
            </section>
            <FooterView/>
        </div>
    );
}
