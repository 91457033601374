import Cart from "models/Cart";
import { PaymentGateway } from "./paymentGateway";
import { getPaymentToken, modalError, mountMultisafePayBody } from "./Request";
import * as Sentry from "@sentry/react";

export  class MultiSafePay extends PaymentGateway{
    set_url_post(url_post): void {
      this.url_post = url_post;
    }
    set_url_ok(url_ok): void {
       this.url_ok = url_ok;

    }
    set_url_ko(url_ko): void {
       this.url_ko = url_ko;
    }

    tokenTicket: string;
    publicToken: string;
    urlBasePost: string;
    namePasarela: string;
    totalAmount: number;
    description: string;
    url_post: string;
    url_ok: string;
    url_ko: string;
    name: string;
    surname: string;
    telephone: string;
    email: string;
    additional_info: any;
    money: string;
    cart: Cart;
    t:any
    environment: any; // dev o prod


    constructor(tokenTicket:string, publicToken: string,  totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, additional_info: any, money: string, cart: Cart,t: any) {
        super();
        this.tokenTicket = tokenTicket;
        this.publicToken = publicToken;
        this.namePasarela = 'MULTISAFEPAY';
        this.totalAmount = totalAmount;
        this.description = description;
        this.name = name;
        this.surname = surname;
        this.telephone = telephone;
        this.email = email;
        this.additional_info = additional_info;
        this.money = money;
        this.cart = cart;
        this.t = t;
        this.environment = process.env.REACT_APP_ENVIRONMENT
      }

    async pay(): Promise<void> {
           
        let body = await mountMultisafePayBody(this.tokenTicket, this.totalAmount*100,  this.description,  this.url_post,  this.url_ok,  this.url_ko,  this.name,  this.surname,  this.telephone,  this.email,  this.additional_info,  this.money, this.cart, this.publicToken);
        let result = await getPaymentToken(body,  this.publicToken, this.namePasarela, this.environment);

        if (result.success) {
          window.location.href = result.data.payment_url;
        } else {
          let message = '';
          switch (result.error_code) {
            case 1032:
                message = this.t("invalidAPIKEY")
                break
            case 1004: 
                message = result.error_info
                break
            case 1002:
                message = this.t("moneyNoValid")
                break
            case 1001: 
                message = this.t("amountNoValid")
                break
            case 1000: 
                message = this.t("methodNotAvailable")
                break
            case 1023:
                message = this.t("gatewayNotAvailable")
                break
            default: 
                message = `${this.t("errorduringPayment")} - `+result.error_info
          }
          Sentry.captureException(new Error(`MultiSafePay - ${message}`));
          modalError(message);
        }
    }

  }